








































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { range, first, find, forEach, isEmpty } from "lodash";
import { ISegment, EFrontType, ESegType } from "../store/state";
import { Engine } from "babylonjs";
import { Action } from "vuex-class";

@Component
export default class Step4 extends Vue {
  @Action REBUILD_FRONT!: () => void;
  @Action COMPLETE_REBUILD!: () => void;
  FT = EFrontType.STANDARD_DOOR_R;
  FType = [
    ["Anschlag rechts", EFrontType.STANDARD_DOOR_R],
    ["Anschlag links", EFrontType.STANDARD_DOOR_L]
  ];

  get currentSegment() {
    return this.$store.getters.getSegmentById(this.activeSegmentId);
  }
  get state() {
    return this.$store.state;
  }
  get segments() {
    return this.state.segments;
  }
  get Door() {
    return this.$store.state.getters.getDoorBySegment(this.activeSegmentId);
  }
  get selectedSegments(): ISegment[] {
    return this.$store.getters.getSelectedSegments;
  }
  get activeSegmentId() {
    const target = first(this.selectedSegments);
    return target ? target.segmentId : "";
  }
  set activeSegmentId(id: string) {
    this.$store.dispatch("SEGMENT_ONCLICK_CB", { id });
  }
  get activeSegmentDoor() {
    return this.$store.getters.getDoorbySegment(this.activeSegmentId);
  }
  get hasDoor() {
    if (!isEmpty(this.$store.getters.getDoorbySegment(this.activeSegmentId))) {
      return true;
    } else {
      return false;
    }
  }
  anzahl = 0;

  ADD_FRONT(segId: number, kind: string) {
    //if (this.hasDoor === true) return;

    const filteredKind = this.currentSegment.kind === "CORNER_SEGMENT" ? "STANDARD_DOOR_L" : kind;
    (window as any)._paq.push([
      "trackEvent",
      "ADD_FRONT",
      JSON.stringify(segId) + ": " + JSON.stringify(filteredKind)
    ]);
    this.$store.dispatch("ADD_FRONT", {
      segmentId: segId,
      SType: first(this.selectedSegments)!.kind,
      FType: filteredKind
    });

    this.REBUILD_FRONT();
  }
  REMOVE_FRONT(segId: string) {
    (window as any)._paq.push(["trackEvent", "REMOVE_FRONT", JSON.stringify(segId)]);
    this.$store.dispatch("REMOVE_FRONT", {
      segmentId: segId
    });
    this.COMPLETE_REBUILD();
  }
}
