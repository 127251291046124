




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as GUI from "babylonjs-gui";
import {
  Engine,
  Scene,
  MeshBuilder,
  Mesh,
  HemisphericLight,
  ArcRotateCamera,
  Vector3,
  Color4,
  StandardMaterial,
  AbstractMesh,
  GizmoManager,
  Color3,
  HighlightLayer,
  Texture,
  PointLight,
  DirectionalLight,
  UtilityLayerRenderer
} from "babylonjs";
import { GLTF2Export, OBJExport } from "babylonjs-serializers";
import { makeSegmentsClickable } from "@/helpers/segment";
import { insertBlock } from "@/helpers/block";
import { map, forEach, zip, find, first, reduce, concat } from "lodash";
import { ISegment, IBlock, IState, ESegType } from "../store/state";
import { FancySceneSingleton, SceneSingleton } from "../helpers/sceneSingleton";
import { Action } from "vuex-class";
import { Route } from "vue-router";
@Component
export default class scene extends Vue {
  @Action REBUILD_SEGMENTS!: () => void;

  private S!: Scene;
  private E!: Engine;
  private Mesh!: AbstractMesh;

  get state() {
    return this.$store.state;
  }
  get segments(): ISegment[] {
    return this.state.segments;
  }
  get blocks(): IBlock[] {
    return this.state.blocks;
  }
  get selectedSegment(): ISegment {
    return first(this.state.getters.getSelectedSegments()) as ISegment;
  }
  get StandardWidth() {
    return this.state.getters.getWingWidth(ESegType.STANDARD_SEGMENT);
  }
  mounted() {
    const Canvas = this.$el as HTMLCanvasElement;
    const E = new Engine(Canvas);
    this.E = E;
    const S = new Scene(E);
    const UI = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", false);

    FancySceneSingleton.setScene(S);
    FancySceneSingleton.setUI(UI);
    (window as any).glb = async (filename: string) => {
      const glb = await GLTF2Export.GLBAsync(S, filename);
      glb.downloadFiles();
    };

    (window as any).obj = async () => {
      const M = S.meshes as Mesh[];
      const obj = await OBJExport.OBJ(M, true, "", true);

      const uriContent = "data:application/octet-stream," + encodeURIComponent(obj);
      const newWindow = window.open(uriContent, "neuesDokument");
    };

    (window as any).mtlString = async () => {
      const M = S.meshes as Mesh[];
      forEach(M, mesh => {
        const mtl = OBJExport.MTL(mesh);
        if (mtl) {
          console.log(mtl);
          console.log("------------------");
        }
      });
    };
    //this.$store.commit("SET_SCENE", { scene: S })
    //S.debugLayer.show();
    let Camera = new ArcRotateCamera(
      "Camera",
      Math.PI / 2,
      Math.PI / 2,
      500,
      new Vector3(0, 0, 0),
      S
    );
    const light = new HemisphericLight("light", new Vector3(60, 300, 100), S);
    const Light = new DirectionalLight("hemiLight", new Vector3(60, 60, 100), S);
    const ground = MeshBuilder.CreateGround("ground", {}, S);
    Camera.attachControl(Canvas, false);
    S.clearColor = new Color4(0, 0, 0, 0.0);
    const hl = new HighlightLayer("hl1", S);
    this.REBUILD_SEGMENTS();

    this.$store.watch(
      (_, { getCameraFocus }) => getCameraFocus,
      focus => {
        Camera.setTarget(focus);
      },
      { immediate: true }
    );

    E.runRenderLoop(() => {
      S.render();
    });

    window.addEventListener("resize", () => {
      E.resize();
    });

    this.S = S;
  }
  beforeDestroy() {
    FancySceneSingleton.reset();
    this.E.dispose();
    this.S.dispose();
  }

  /*async exportScene() {
    const glb = await GLTF2Export.GLBAsync(this.S, "SporkiScene");
    glb.downloadFiles();
  }*/
}
