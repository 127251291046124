

































































/*<b>breite oben</b>
    <input class="slider" type="range" min="0" :max="globalWidth" v-model.number="width_top" />*/
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Vuex from "vuex";
import segment from "@/components/segment.vue";
import { ISegment, ESegType, IState } from "../store/state";
import { first, isEmpty } from "lodash";
import { Vector2 } from "babylonjs";
import { Action } from "vuex-class";
@Component({
  components: {
    segment
  }
})
export default class Step1 extends Vue {
  @Action CHANGE_GENERAL_DIMENSION!: (payload: Partial<IState["general_dimensions"]>) => void;
  @Action REBUILD_BLOCKS!: () => void;

  get max_l() {
    return !isEmpty(this.$store.getters.getWingSegments(ESegType.EXTENSION_SEGMENT))
      ? this.$store.getters.getWingWidth(ESegType.EXTENSION_SEGMENT)
      : this.globalWidth - this.width_right - 10;
  }
  get max_r() {
    return !isEmpty(this.$store.getters.getWingSegments(ESegType.EXTENSION_SEGMENT))
      ? this.$store.getters.getWingWidth(ESegType.STANDARD_SEGMENT)
      : this.globalWidth - this.width_left - 10;
  }
  get max_height() {
    return this.$store.state.general_dimensions.height_base;
  }
  get segments() {
    return this.$store.state.segments;
  }
  get selectedSegments(): ISegment[] {
    return this.$store.getters.getSelectedSegments;
  }
  get activeSegmentId() {
    const target = first(this.selectedSegments);
    return target ? target.segmentId : "";
  }
  get globalWidth() {
    return this.$store.getters.globalWidth;
  }

  get height_left() {
    return this.$store.state.general_dimensions.height_left;
  }
  get height_right() {
    return this.$store.state.general_dimensions.height_right;
  }
  get width_left() {
    return this.$store.state.general_dimensions.width_left;
  }
  get width_right() {
    return this.$store.state.general_dimensions.width_right;
  }
  set height_left(height_left: number) {
    if (height_left < 10 || height_left > this.max_height) return;
    (window as any)._paq.push([
      "trackEvent",
      "CHANGE_HEIGHT_LEFT_SLOPE",
      JSON.stringify(height_left)
    ]);
    this.CHANGE_GENERAL_DIMENSION({ height_left });
  }
  set height_right(height_right: number) {
    if (height_right < 10 || height_right > this.max_height) return;
    (window as any)._paq.push([
      "trackEvent",
      "CHANGE_HEIGHT_RIGHT_SLOPE",
      JSON.stringify(height_right)
    ]);
    this.CHANGE_GENERAL_DIMENSION({ height_right });
  }
  set width_left(width_left: number) {
    if (width_left > this.max_l || width_left == 1) return;
    (window as any)._paq.push([
      "trackEvent",
      "CHANGE_WIDTH_LEFT_SLOPE",
      JSON.stringify(width_left)
    ]);
    this.CHANGE_GENERAL_DIMENSION({ width_left });
  }
  set width_right(width_right: number) {
    if (width_right > this.max_r || width_right == 1) return;
    (window as any)._paq.push([
      "trackEvent",
      "CHANGE_WIDTH_RIGHT_SLOPE",
      JSON.stringify(width_right)
    ]);
    this.CHANGE_GENERAL_DIMENSION({ width_right });
  }
}
