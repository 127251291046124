



















































/* eslint-disable */
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Route } from "vue-router";
import {
  range,
  isEmpty,
  forEach,
  zip,
  drop,
  dropRight,
  groupBy,
  isEqual,
  reduce,
  map,
  countBy
} from "lodash";
import Step1 from "@/components/step1.vue";
import Step2 from "@/components/step2.vue";
import Step3 from "@/components/step3.vue";
import Step4 from "@/components/step4.vue";
import Error from "@/components/Error.vue";
import scene from "@/components/Scene.vue";
import { saveAs } from "file-saver";
import baseGeometry from "@/components/baseGeometry.vue";
import {
  Engine,
  Scene,
  MeshBuilder,
  HemisphericLight,
  ArcRotateCamera,
  Vector3,
  Color4,
  StandardMaterial
} from "babylonjs";
import { EBlockType, ISegment } from "@/store/state";

@Component({
  components: {
    scene,
    baseGeometry
  }
})
export default class Step extends Vue {
  @Action CHECK_FRONT_OPEN!: () => void;

  stepComp(index: number) {
    switch (index) {
      case 1:
        return Step1;
      case 2:
        return Step2;
      case 3:
        return Step3;
      case 4:
        return Step4;
    }
  }

  title(index: number) {
    switch (index) {
      case 1:
        return "Basiskonfiguration";
      case 2:
        return "Seitenschrägen";
      case 3:
        return "Add-On";
      case 4:
        return "Türen";
    }
  }
  blockType = [
    ["Regalboden", "Regalböden", "SHELF"],
    ["Kleiderstange", "Kleiderstangen", "RAIL"],
    ["Schublade", "Schubladen", "DRAWER"]
  ];
  get Range() {
    return range(1, 5);
  }
  get foreground() {
    if (!isEmpty(this.$store.state.foreground)) {
      return this.$store.state.foreground;
    }
  }
  get hasCorner() {
    if (this.$store.getters.hasCornerSegment) {
      return this.$store.getters.hasCornerSegment;
    }
  }
  Door(segmentId: string) {
    return this.$store.getters.hasDoor(segmentId);
  }
  get checked() {
    return this.$store.state.general_dimensions.front_open;
  }
  get state() {
    return this.$store.state;
  }
  get wallParts() {
    return this.$store.getters.wallParts;
  }
  get baseParts() {
    return this.$store.getters.baseParts;
  }
  get topParts() {
    return this.$store.getters.topParts;
  }
  get baseCoverParts() {
    return this.$store.getters.baseCoverParts;
  }
  get drawerParts() {
    return this.$store.getters.drawerParts;
  }
  get shelfParts() {
    return this.$store.getters.shelfParts;
  }
  get railParts() {
    return this.$store.getters.railParts;
  }
  countBlocks(segmentId: string, type: string) {
    switch (type) {
      case "SHELF":
        return this.$store.getters.getShelvesBySegment(segmentId, type).length;
      case "DRAWER":
        return this.$store.getters.getDrawersBySegment(segmentId, type).length;
      case "RAIL":
        return this.$store.getters.getRailsBySegment(segmentId, type).length;
    }
  }
  set checked(newVal) {
    (window as any)._paq.push(["trackEvent", "TOGGLE_FRONT_OPEN", JSON.stringify(newVal)]);
    this.$store.commit("SET_FRONT_OPEN", { open: newVal });
    this.CHECK_FRONT_OPEN();
  }

  addToClipboard() {
    (window as any)._paq.push([
      "trackEvent",
      "ADD_SUMMARY_TO_CLIPBOARD",
      "add summary to clipboard"
    ]);
    const el = document.createElement("textarea");
    el.value = "Stückliste - einbauschrank.shop \r\n\r\n" + this.writeSummary();
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  downloadAsTxt() {
    (window as any)._paq.push(["trackEvent", "DOWNLOAD_SUMMARY", "download Summary"]);
    var blob = new Blob(["Stückliste - einbauschrank.shop \r\n\r\n" + this.writeSummary()], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(blob, "stueckliste_einbauschrank_shop.txt");
  }

  sortParts(parts: [number[]]) {
    const partsToString = map(parts, part => {
      return part.join(" x ");
    });
    return countBy(partsToString);
  }
  //TODO: Stückliste pro brett aufteilen!
  writeSummary() {
    let text = "";
    text += "Segmentdeckel: \r\n";
    forEach(this.sortParts(this.topParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    text += "Segmentwände: \r\n";
    forEach(this.sortParts(this.wallParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    text += "Segmentböden: \r\n";
    forEach(this.sortParts(this.baseParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    text += "Segmentblenden: \r\n";
    forEach(this.sortParts(this.baseCoverParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    !isEmpty(this.drawerParts) ? (text += "Schubladenteile: \r\n") : 0;
    forEach(this.sortParts(this.drawerParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    !isEmpty(this.shelfParts) ? (text += "Regalböden: \r\n") : 0;
    forEach(this.sortParts(this.shelfParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    !isEmpty(this.railParts) ? (text += "Kleiderstangen: \r\n") : 0;
    forEach(this.sortParts(this.railParts), function(value, key) {
      text += "\t" + value + " mal " + key + " cm \r\n";
    });
    return text;
  }
  openNav() {
    (window as any)._paq.push(["trackEvent", "OPEN_SUMMARY", "open summary"]);
    const sidebar = document.getElementById("SummarySidebar");
    if (sidebar) {
      sidebar.classList.add("w100");
      sidebar.style.width = "30vw";
    }
  }

  closeNav() {
    (window as any)._paq.push(["trackEvent", "CLOSE_SUMMARY", "close summary"]);
    const sidebar = document.getElementById("SummarySidebar");
    if (sidebar) {
      sidebar.classList.remove("w100");
      sidebar.style.width = "0";
      sidebar.style.marginLeft = "0";
    }
  }
}
