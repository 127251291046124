




































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { range, first, find, forEach, round } from "lodash";
import { ISegment, EBlockType } from "../store/state";
import { Action } from "vuex-class";

@Component
export default class Step2 extends Vue {
  @Action REBUILD_SEGMENTS!: () => void;
  @Action REBUILD_BLOCKS!: () => void;
  get state() {
    return this.$store.state;
  }
  get segments() {
    return this.state.segments;
  }
  get blocks() {
    return this.state.blocks;
  }
  get selectedSegments(): ISegment[] {
    return this.$store.getters.getSelectedSegments;
  }
  get activeSegmentId() {
    const target = first(this.selectedSegments);
    return target ? target.segmentId : "";
  }
  set activeSegmentId(id: string) {
    this.$store.dispatch("SEGMENT_ONCLICK_CB", { id });
  }

  shelfCount = 0;
  railCount = 0;
  drawerCount = 0;

  REMOVE_BLOCK(bId: string) {
    this.$store.commit("REMOVE_BLOCK", { bId });
  }
  ADD_BLOCK(segId: number, kind: EBlockType, anzahl: number) {
    (window as any)._paq.push([
      "trackEvent",
      "ADD_ADD_ON",
      JSON.stringify(segId) + ": " + JSON.stringify(kind) + " " + JSON.stringify(anzahl)
    ]);
    forEach(this.blocks, block => {
      this.REMOVE_BLOCK(block.blockId);
    });

    const height = this.state.general_dimensions.height_base;
    forEach(range(1, anzahl + 1), zahl => {
      let offset = round((height / (anzahl + 1)) * zahl, -1);
      this.$store.commit("ADD_BLOCK", { segmentId: segId, offset, kind });
    });
    this.REBUILD_BLOCKS();
  }
  resetBlocks() {
    //(window as any)._paq.push(["trackEvent", "RESET_ADD_ONS"]);
    this.$store.commit("REMOVE_SEGMENT_BLOCKS", { segmentId: this.activeSegmentId });
    this.$store.dispatch("COMPLETE_REBUILD");
  }
}
