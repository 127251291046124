















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import Vuex from "vuex";
import segment from "@/components/segment.vue";
import { ISegment, ESegType, IState } from "../store/state";
import { first, isEmpty, round } from "lodash";
import { Vector2 } from "babylonjs";
@Component({
  components: {
    segment
  }
})
export default class Step1 extends Vue {
  @Action ADD_SEGMENT!: (payload: { SType: ESegType }) => void;
  @Action COMPLETE_REBUILD!: () => void;
  @Action REBUILD_SEGMENTS!: () => void;
  @Action CHANGE_GENERAL_DIMENSION!: (payload: Partial<IState["general_dimensions"]>) => void;
  addSegment() {
    (window as any)._paq.push(["trackEvent", "ADD_SEGMENT", JSON.stringify(this.ST)]);
    this.ADD_SEGMENT({ SType: this.ST });
    this.COMPLETE_REBUILD();
  }

  ST = ESegType.STANDARD_SEGMENT;
  activeSegments = this.base_segments;
  SType = [["Standard", ESegType.STANDARD_SEGMENT], ["Extension", ESegType.EXTENSION_SEGMENT]];
  get height() {
    return this.$store.state.general_dimensions.height_base;
  }
  get depth() {
    return this.$store.state.general_dimensions.depth;
  }
  get base_segments() {
    return this.$store.getters.getWingSegments(ESegType.STANDARD_SEGMENT);
  }
  get segments() {
    return this.$store.state.segments;
  }
  get extension_segments() {
    return this.$store.getters.getWingSegments(ESegType.EXTENSION_SEGMENT);
  }
  get selectedSegments(): ISegment[] {
    return this.$store.getters.getSelectedSegments;
  }
  get activeSegmentId() {
    const target = first(this.selectedSegments);
    return target ? target.segmentId : "";
  }
  get width() {
    const target = first(this.selectedSegments);
    return target ? target.width : NaN;
  }
  get globalWidth() {
    return this.$store.getters.globalWidth;
  }
  get path() {
    return this.$store.getters.segmentProfile;
  }
  get corner_segment() {
    if (!isEmpty(this.$store.getters.getCornerSegment)) {
      return this.$store.getters.getCornerSegment;
    }
  }
  set width(payload: number) {
    if (payload < 60 || payload > 120) return;
    if (payload % 10 !== 0) payload = round(payload, -1);
    (window as any)._paq.push(["trackEvent", "CHANGE_WIDTH", JSON.stringify(payload)]);
    this.$store.dispatch("CHANGE_WIDTH", {
      segmentId: this.activeSegmentId,
      width: payload
    });
    this.REBUILD_SEGMENTS();
  }
  set activeSegmentId(id: string) {
    this.$store.dispatch("SEGMENT_ONCLICK_CB", { id });
  }
  set height(height_base: number) {
    if (height_base < 60 || height_base > 300) return;
    (window as any)._paq.push(["trackEvent", "CHANGE_HEIGHT", JSON.stringify(height_base)]);
    this.CHANGE_GENERAL_DIMENSION({ height_base });
  }
  set depth(depth: number) {
    if (depth < 20 || depth > 90) return;
    (window as any)._paq.push(["trackEvent", "CHANGE_DEPTH", JSON.stringify(depth)]);
    this.CHANGE_GENERAL_DIMENSION({ depth });
  }

  REMOVE_SEGMENT(segmentId: string) {
    (window as any)._paq.push(["trackEvent", "REMOVE_SEGMENT", JSON.stringify(segmentId)]);
    this.$store.dispatch("REMOVE_SEGMENT", { segmentId });
    this.$store.commit("REMOVE_SEGMENT_BLOCKS", { segmentId });
    this.$store.commit("REMOVE_SEGMENT_FRONT", { segmentId });
  }
}
