







import { Component, Prop, Vue } from "vue-property-decorator";
import Vuex from "vuex";
import { ISegment } from "../store/state";
import { Action } from "vuex-class";

@Component
export default class Segment extends Vue {
  @Action REBUILD_SEGMENTS!: () => void;
  @Prop() id!: string;
  get width() {
    return this.segment.width;
  }
  set width(width: number) {
    if (width >= 60) {
      this.CHANGE_WIDTH(width);
    }
  }
  get segment() {
    return this.$store.getters.getSegmentById(this.id);
  }
  CHANGE_WIDTH(payload: Partial<ISegment["width"]>) {
    this.$store.commit("CHANGE_WIDTH", {
      id: this.id,
      width: payload
    });
    this.REBUILD_SEGMENTS();
  }
}
